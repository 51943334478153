.header {
  display: flex;
  width: 100%;

  & > * {
    flex-grow: 1;
  }
  .hubs {
    display: flex;
    max-width: 180px;
    min-width: 180px;
    flex-direction: column;
    margin-left: 35px;
    padding: 16px 8px;
    justify-content: center;
    .primary {
      font-size: 32px;
    }
    .secondary {
      color: #b6b6b6;
      font-size: 14px;
    }
  }
  .organ-group {
    display: flex;
    color: black;
    border-left: 2px solid black;
    .title {
      font-size: 20px;
      font-weight: 700;
    }
    .days-group {
      display: flex;
      flex-grow: 1;
      .day-group {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-end;
        &.today {
          &.liver {
            background: #9bdd67;
          }
          &.heart {
            background: #cc9afd;
          }
          &.lung {
            background: #87c8ff;
          }
        }
        &.tomorrow {
          &.liver {
            background: #83bc62;
          }
          &.heart {
            background: #a27cc9;
          }
          &.lung {
            background: #6ca5d6;
          }
        }
        .title {
          padding: 8px 16px;
          display: flex;
          align-items: center;
          gap: 8px;
          padding-inline: 24px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
        .subtitle {
          padding: 8px 16px;
        }
        .subheader {
          display: flex;
          background: black;
          color: #efecea;
          .title {
            font-size: 14px;
            width: calc(100% / 3);
            justify-content: center;
            padding-inline: 16px;
          }
        }
      }
    }
  }
}
