.mapContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
}

//.hubsSVG {
//  pointer-events: none;
//}

.usaMapSVG {
  pointer-events: none;

  text {
    paint-order: stroke;
    stroke: #1D2A37;
    stroke-width: 3px;
  }
}
