.layout {
  border: 1px solid #FF975D;
  background: transparent;
  border-radius: 20px 1px;
  position: relative;
  width: 100%;
  padding: 17px 12px;
  box-sizing: border-box;
  transition: opacity 0.25s linear;

  hr {
    opacity: 0.2;
    margin: 13px 0;
    border: 1px solid #FFFFFF;
  }
}

.alertTitle {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FF975D;
  margin-bottom: 8px;
  margin-top: 0;
}

.donorPlace {
  display: block;
  font-size: 16px;
  color: #FF975D;
  margin-bottom: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.donorInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
  gap: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.donorTime {
  display: block;
  font-size: 14px;
  color: #FFFFFF;
}

.label {
  font-family: 'JetBrains Mono', monospace;
  font-feature-settings: 'zero' 1;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #FF975D;
  margin-left: auto;
}

.txCode {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FF975D;
  margin-left: auto;
}
