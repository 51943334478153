.hubStatusCard {
  position: absolute;
  width: 120px;
  height: 120px;
}

.staffIcon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.north, .south {
  & .surgeon {
    left: 56px;
    height: 36px;
    flex-direction: row-reverse;

    & .counts {
      margin-left: 4px;
      flex-direction: column;

      &.liver {
        margin-left: 5px;
      }
    }
  }

  & .nurse {
    right: 56px;
    margin: auto;
    height: 36px;

    & .counts {
      margin-right: 4px;
      flex-direction: column;

      &.liver {
        margin-right: 5px;
      }
    }
  }

  & .ocs {
    left: 0;
    right: 0;
    margin: auto;
    width: 36px;
  }

  & .abbreviation {
    left: 0;
    right: 0;
  }
}

.north {
  & .surgeon {
    bottom: 32px;
  }

  & .nurse {
    bottom: 32px;
  }

  & .ocs {
    bottom: 56px;
    flex-direction: column;

    & .counts {
      margin-bottom: 4px;

      &.liver {
        margin-bottom: 5px;
      }
    }
  }

  & .abbreviation {
    bottom: 7px;
  }
}

.south {
  & .surgeon {
    top: 32px;
  }

  & .nurse {
    top: 32px;
  }

  & .ocs {
    top: 56px;
    flex-direction: column-reverse;

    & .counts {
      margin-top: 4px;
    }
  }

  & .abbreviation {
    top: 9px;
  }
}

.west, .east {
  & .surgeon {
    width: 36px;
    bottom: 56px;
    flex-direction: column;

    & .counts {
      margin-bottom: 4px;
    }
  }

  & .ocs {
    width: 36px;
    top: 56px;
    flex-direction: column-reverse;

    & .counts {
      margin-top: 4px;
    }
  }

  & .nurse {
    top: 0;
    bottom: 0;
    margin: auto;
    height: 36px;

    & .counts {
      flex-direction: column;
    }
  }

  & .abbreviation {
    top: 0;
    bottom: 0;
  }
}

.west {
  & .surgeon {
    right: 42px;
  }

  & .ocs {
    right: 42px;
  }

  & .nurse {
    right: 66px;
    flex-direction: row;

    & .counts {
      margin-right: 4px;
    }
  }

  & .abbreviation {
    right: 0;
  }
}

.east {
  & .surgeon {
    left: 45px;
  }

  & .ocs {
    left: 45px;
  }

  & .nurse {
    left: 69px;
    flex-direction: row-reverse;

    & .counts {
      margin-left: 4px;
    }
  }

  & .abbreviation {
    left: 0;
  }
}

.counts {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.square {
  width: 5px;
  height: 5px;
}

.rhombus {
  width: 5px;
  height: 5px;
  rotate: 45deg;
}

.abbreviation {
  position: absolute;
  width: min-content;
  height: min-content;
  margin: auto;
  font-size: 20px;
  font-weight: 700;
}

// hub cards positions

// east
.AND {
  top: 228px;
  right: 36px;
}

.NYC {
  top: 453px;
  right: 50px;
}

.DUR {
  top: 648px;
  right: 127px;
}

.ATL {
  top: 768px;
  right: 234px;
}

// south

.TPA {
  bottom: 21px;
  right: 435px;
}

.HOU {
  bottom: 21px;
  right: 731px;
}

.DAL {
  bottom: 21px;
  left: 915px;
}

.PHX {
  bottom: 142px;
  left: 614px;
}

.SLC {
  top: 286px;
  left: 270px;
}

// west

.SEA {
  top: 25px;
  left: 410px;
}

.SFO {
  top: 466px;
  left: 273px;
}

.LOS {
  top: 641px;
  left: 333px;
}

.SAN {
  top: 763px;
  left: 451px;
}

// north

.CLE {
  top: 246px;
  right: 363px;
}

.YIP {
  top: 176px;
  right: 475px;
}

.CHI {
  top: 135px;
  right: 627px;
}

.MSP {
  top: 90px;
  right: 760px;
}
