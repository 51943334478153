.layout {
  width: 1920px;
  height: 1080px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 15px 20px;
  gap: 10px;
  box-sizing: border-box;
  align-content: flex-start;
}
