.pieChart {
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  .container {
    position: relative;
    width: 108px;
    height: 108px;
    justify-content: center;
    align-items: center;
    display: flex;

    &Bg {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: -1;
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        z-index: 10;
        background: black;
        border-radius: 50%;
        top: 4px;
        left: 4px;
      }
    }
    .contentWrapper {
      display: flex;
      height: 100%;
      box-sizing: border-box;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .icon {
        color: #7f8083;
        & svg {
          width: auto;
          height: 24px;
          color: inherit;
        }
      }
      .content {
        display: flex;
        gap: 4px;
        align-items: flex-end;
        flex-wrap: wrap;
        .main {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            margin-bottom: -10px;
            svg {
              height: 30px;
            }
          }
          .text {
            color: #dddddd;
            font-weight: 500;
            font-size: 24px;
          }
        }
        .extra {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            svg {
              height: 22px;
            }
            margin-bottom: -8px;
          }
          .text {
            font-size: 14px;
            padding-bottom: 3px;
          }
        }
        .hours {
          color: #dddddd;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
  .label {
    color: #a3a7ab;
    font-size: 14px;
    text-align: center;
    max-width: 140px;
  }
}
