.tripCard {
  border-radius: 2px;
  margin: 3% 3% 6% 3%;
  min-height: 12.85%;
  display: flex;
  flex-direction: column;
  padding: 3%;
  border: 1px solid #1d2a37;
  background-color: rgba(29, 42, 55, 0.5); //default

  &.ok {
  }
  &.accept {
    background-color: rgba(0, 231, 51, 0.1);
  }
  &.warning {
    background-color: rgba(255, 56, 68, 0.1);
  }

  &.errorsPageStyles {
    margin: 0;
    width: 100%;
    height: 100%;
  }
}

.tripCardHeader {
  flex-shrink: 0;
  margin-bottom: 24px;
}

.tripCardHeader,
.tripBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tripBottom {
  flex-shrink: 0;
  height: 20px;
}

.tripProgress {
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-bottom: 12px;
}

.progressBlock {
  position: relative;
  display: block;
  margin-bottom: 6px;
}

.progressLine {
  height: 4px;
  width: 100%;
  border-radius: 4px;
  background-color: #000;
}

.activeProgressLine {
  position: absolute;
  height: 4px;
  width: 100%;
  border-radius: 4px;
}

.activeProgress {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.line {
  position: absolute;
  bottom: 11px;
  height: 4px;
  border-radius: 4px;
  background-color: #475869;
  z-index: 9;
}

.transports {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  z-index: 11;
  top: -14px;
}

.transportItem {
  display: flex;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  z-index: 3;
  margin-left: 0;
  color: #ffffff;
  transform: translateX(-50%);
}

.transportCircle {
  position: absolute;
  align-items: center;
  transform: translateX(-15%);
  width: 5px;
  height: 5px;
  display: none;
  top: 12px;

  &.transportCircleShow {
    display: block;
  }

  & .circle {
    margin-top: 0;
  }
}

.items {
  height: 20px;
  width: 100%;
  bottom: 5px;
  position: absolute;
  background-color: transparent;
  z-index: 10;

  &Organ {
    z-index: 9;
  }
}

.organIcon {
  position: absolute;
  z-index: 9;
  transform: translateX(-50%);

  &End {
    transform: translateX(-100%);
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
}

.itemsGroup {
  position: absolute;
  bottom: 25px;
  height: 100%;
  margin-left: 0;

  &Margin {
    margin-left: 3.5%;
  }
}

.itemBig {
  position: absolute;
  display: flex;
  bottom: 0;
  transform: translateX(-50%);
  margin-left: 3%;

  &.pickUp {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }
}

.itemSmall {
  position: absolute;
  display: flex;
  bottom: 10px;
  transform: translateX(-50%);
}

.checkpoints {
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: 12px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  border: 1.5px solid #b6b6b6;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.tripCardId {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.errorIcon {
  display: flex;
  color: #ff3844;
}

.unosId {
  font-family: 'JetBrains Mono', monospace;
  font-feature-settings: 'zero' 1;
  font-weight: 500;
  font-size: 16px;
}

.donorType {
  font-size: 14px;
  font-weight: 500;
}

.tripTime {
  font-size: 14px;
  font-weight: 500;
}

.tripCardEta {
  color: #87888b;
}

.tripTimer {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}

.pathLabels {
  position: relative;
  height: 20px;
}

.pathLabelText {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  display: flex;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.status {
  background-color: #000000;
  padding: 4px;
  font-size: 10px;
  border-radius: 5px;
}

.tripAircrafts {
  display: flex;
  width: 100%;
  height: 24px;
  margin-bottom: 6px;
}

.flightId {
  border-radius: 2px;
  padding: 2px 4px;
  background-color: #000000;
  font-family: 'JetBrains Mono', monospace;
  font-feature-settings: 'zero' 1;
  font-weight: 500;
  font-size: 14px;
}
