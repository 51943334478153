.alerts {
  position: relative;
  flex: 0 0 6.214%;
  max-width: 6.214%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: calc(100% - 100px);
  padding-top: 100px;

  &.errorsPageStyles {
    justify-content: initial;
    width: 100%;
    max-width: 33%;
    flex: 1 0 100%;
    height: 100%;
    padding-top: 0;
  }
}
