.timers {
  position: fixed;
  bottom: 5px;
  right: 5px;
  display: flex;
  gap: 11px;
  z-index: 100;
}

.time {
  font-family: 'JetBrains Mono', monospace;
  font-size: 10px;
  color: #7F8083CC;
}
