.loaderWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderAbsolute {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.loader {
  width: 35px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span {
    display: inline-block;
    width: 5px;
    height: 20px;
    background-color: #ddd;

    &:nth-child(1) {
      animation: loaderGrow 1s ease-in-out infinite;
    }

    &:nth-child(2) {
      animation: loaderGrow 1s ease-in-out 0.15s infinite;
    }

    &:nth-child(3) {
      animation: loaderGrow 1s ease-in-out 0.3s infinite;
    }

    &:nth-child(4) {
      animation: loaderGrow 1s ease-in-out 0.45s infinite;
    }
  }
}

@keyframes loaderGrow {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}
