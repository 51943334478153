.DataErrorsList {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 25px;

  &Wrapper {
    overflow: hidden;
    position: relative;

    &FadeAfter {
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 5px);
        height: 40px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        pointer-events: none;
        z-index: 20;
      }
    }

    &FadeBefore {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 5px);
        height: 40px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        pointer-events: none;
        z-index: 20;
      }
    }
  }

  &::-webkit-scrollbar {
    width: 5px;

    &-track {
      background: transparent;
    }

    &-thumb {
      max-height: 20px;
      background: #7f8083;
      border-right: none;
      border-left: none;
      &:vertical {
        &:hover {
          background: #ff975d;
        }

        &:active {
          background: #ff975d;
        }
      }
    }
  }

  .caption {
    font-size: 28px;
    font-weight: 600;
    color: #FF975D;
    padding-top: 25px;
    text-transform: uppercase;

    &.disabled {
      color: #7F8083 !important;
    }

    &.error {
      color: #FF3844;

      .errorText {
        font-family: 'JetBrains Mono', monospace;
        margin-left: 17px;
      }
    }
  }

  .text {
    font-size: 16px;
    font-weight: 400;
    color: #7F8083;
    line-height: 32px;
  }
}
