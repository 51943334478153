.layout {
  width: 1920px;
  display: flex;
  flex-direction: column;
}

.page {
  width: 100%;
  zoom: 0.5;
}

.pageSafari {
  transform: scale(0.5);
  transform-origin: 50% 50%;
  margin: calc(-1080px / 4) calc(-1920px / 4);
}

.pageSafariTop {
  margin: calc(-1080px / 4) calc(-1920px / 2);
}

.bottom {
  width: 100%;
  display: flex;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #364B5F;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &:after {
    content: ' ';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #364B5F;
    top: 0;
    left: calc(50% - 0.5px);
    z-index: 10;
  }
}

.window {
  position: relative;
  flex: 1 0 50%;
}
