.layout {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 3840px;
  height: 1080px;

  &.debug > div {
    border: 1px solid red;
  }
}

.map {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: -3%;
  margin-right: 3%;
}

.endLine {
  position: absolute;
  width: 3840px;
  top: 1080px;
}
