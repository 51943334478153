.layout {
  display: flex;
  gap: 32px;
  font-family: 'JetBrains Mono', monospace;
  font-feature-settings: 'zero' 1;
  justify-content: space-around;
  flex-direction: column;
  padding: 40px;
  .header {
    .title {
      line-height: 52px;
      font-size: 24px;
      color: #dddddd;
    }
    .subtitle {
      font-size: 16px;
      line-height: 32px;
      color: #7f8083;
    }
  }
  .content {
    display: flex;
    gap: 32px;
    .card {
      display: flex;
      gap: 60px;
      flex-direction: column;
      font-family: 'JetBrains Mono', monospace;
      font-feature-settings: 'zero' 1;
      flex-grow: 1;
      border: 2px solid #1d2a37;
      border-radius: 20px;
      padding: 60px 40px;

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        color: #b6b6b6;

        .count {
          font-size: 22px;
        }

        .helperText {
          font-size: 14px;
          text-align: center;
        }
      }

      .content {
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }
  }
}
