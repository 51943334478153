.layout {
  position: absolute;
  left: 40px;
  top: 270px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  font-family: 'JetBrains Mono', monospace;
  font-feature-settings: 'zero' 1;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  color: #B6B6B6;
}

.count {
  font-size: 14px;
}

.helperText {
  font-size: 10px;
}

.statusText {
  color: #7F8083CC;
  font-size: 10px;
}

.pieChart {
  position: relative;
  width: 108px;
  height: 108px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &Fragment {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.chartContent {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #000000;
  z-index: 10;
  padding: 4px 11px 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  box-sizing: border-box;
}

.percents {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
  font-size: 24px;
  font-weight: 500;
  color: #DDDDDD;
}

.text {
  font-size: 10px;
  color: #7F8083;
  text-align: center;
}

.icons {
  display: flex;
  gap: 6px;
  align-items: flex-end;
  flex-grow: 1;
}

.airGround {
  color: #0E5795;
}

.ground {
  color: #62666C;
}

.iconText {
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    width: 22px;
    height: 22px;
    margin-bottom: -5px;
  }

  &Big {
    font-size: 24px;

    & svg {
      width: 30px;
      height: 30px;
      margin-bottom: -10px;
    }
  }

  &Small {
    font-size: 14px;
    padding-bottom: 3px;
  }
}


