.appStatusLayout {
  position: absolute;
  bottom: 32px;
  left: 200px;
  width: 740px;
  height: 60px;
}

.infoList {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 10px;
}

.infoText {
  margin: 0;
  font-size: 16px;
  color: #7F8083;
}

.errorInfoText {
  margin: 0;
  font-size: 16px;
  color: #FF3844;
}

.icon {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #7F8083;

  & svg {
    width: 22px;
    height: 22px;
  }
}

.errorColor {
  color: #FF3844;
}

.errorInfo {
  display: flex;
  gap: 6px;
  margin-left: -4px;
  margin-top: -7px;
}

.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 16px;
  color: #FF3844;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
