.trips {
  flex: 0 0 8.3333%;
  max-width: 8.3333%;
  position: relative;
  width: 100%;
  height: 100%;

  &.errorsPageStyles {
    flex-direction: column;
    float: right;
    //border: 1px solid orange;
    max-width: 33%;
    display: inline-flex;
    vertical-align: top;
    padding: 10px;
  }
}
