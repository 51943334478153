.timezones {
  position: absolute;
  left: -247px;
  top: 0;
  width: 3000px;
  //border: 1px solid red;

  .timezone {
    display: inline-block;
    font-weight: 500;
    font-size: 26px;
    color: #7591AD;
    margin: 14px 131px;
    text-align: center;
    line-height: 37px;
    //border: 1px solid red;

    .name {
      font-weight: 600;
      opacity: 40%;
    }
  }
}



