.lastUpdate {
  font-size: 52px;
  font-weight: 600;
  color: #7F8083;
  padding-top: 40px;

  &.error {
    color: #FF3844;
  }
}
