.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 106%;
  height: 100%;
}

.aircraftsContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlaySVG {
  path.curve {
    stroke-width: 1;
    stroke: #8a8a8a;
    stroke-dasharray: 5 5;
    fill: none;
  }

  path.thirdPartyAviation {
    stroke-width: 1;
    stroke: #8a8a8a;
    stroke-dasharray: 2 2;
    fill: none;
  }

  path.tmdxAviation {
    stroke-width: 1;
    stroke: #8a8a8a;
    stroke-dasharray: none;
    fill: none;
  }
}

.directionPoint {
  fill: transparent;
  stroke-width: 1.25;
  filter: drop-shadow(0 0.75px 1.3px #00000040);
}

.caseItems {
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.7));
}

.absolutePosition {
  position: absolute;
  transform-origin: center;
  transform: translate(10px, -20px);
  z-index: 20;

  &.aircraftMapPosition {
    transform: translate(16px, -36px);
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.7));

    &WithLabel {
      transform: translate(16px, -53px);
    }
  }
}

.caseOrgan {
  position: absolute;
  transform: translate(-50%, -110%);
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.7));
}

.caseTeam {
  display: flex;
  align-items: center;
}

.caseTransport {
  width: fit-content;
  height: fit-content;
  margin: -8px 0 -10px;
  display: flex;
  transform: scale(1, 1);

  &.westDirection {
    transform: scale(-1, 1);
  }

  &.airNonMao {
    margin: -6px 0 -7px;
  }
}

.caseLabel {
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  font-weight: 500;
  font-feature-settings: 'zero' 1;
  text-align: center;
}

.newCaseOrgan {
  position: absolute;
  transform: translate(-50%, calc(-100% - 4px));
  z-index: 2;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.7));
}

.line {
  opacity: 0.5;
}

.aircraftOnMap {
  display: flex;
}

.onFly {
  z-index: 1;
}

.onGround {
  z-index: 100;
}

.registration {
  font-family: 'JetBrains Mono', monospace;
  font-size: 28px;
  line-height: 37px;
  font-weight: 500;
  font-feature-settings: 'zero' 1;
  color: #7f8083;
}

.aircraftMap {
  &Icon {
    width: 37px;
    height: 37px;
    color: #7f8083;
    margin: 0 4px;

    &.planeWestDirection {
      transform: scale(-1, 1);
    }
  }

  &heart {
    color: #cc9afd;
  }

  &liver {
    color: #affb74;
  }

  &lung {
    color: #87c8ff;
  }
  &OnMaintenance {
    &.type-AOG {
      color: #f88;
      &.aircraftTime {
        color: #f88;
      }
    }
  }
}

.aircraftMapLate {
  color: #ff3844;
}

.aircraftMapReturnToBase {
  color: #7f8083;
}

.aircraftMapIconOnGround,
.aircraftMapIconOnMaintenance {
  width: 37px;
  height: 37px;
  color: #7f8083;
  margin: 0 4px;
}

.aircraftInfo {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;

  &WithLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.aircraftAirportInfo {
  display: flex;
  align-items: center;
}

.aircraftTime {
  width: 50px;
  height: 31px;
  border-radius: 4px 0 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  background-color: #7f808326;
  color: #7f8083;
  svg {
    fill: currentColor;
    color: inherit;
  }
}

.duty {
  &White {
    color: white;

    &.aircraftTime {
      background-color: #ffffff26;
    }
  }

  &Mint {
    color: #8bd8ae;

    &.aircraftTime {
      background-color: #8bd8ae26;
    }
  }

  &Blue {
    color: #7591ad;

    &.aircraftTime {
      background-color: #7591ad26;
    }
  }
}

.pointOnMap {
  fill: transparent;
  stroke-width: 1.5;
  filter: drop-shadow(0 0.75px 1.3px #00000040);
}

.itemsList {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.aircraftsList {
  gap: 10px;
}

.clusterLine {
  stroke-width: 1;
  opacity: 25%;
}

.startAirportLine {
  stroke-dasharray: 4 4;
  opacity: 80%;
}

.endAirportLine {
  opacity: 80%;
}

.aircraftUnosLabel {
  width: 100%;
  flex-grow: 1;
  font-family: 'JetBrains Mono', monospace;
  font-size: 18px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 2px;
}
