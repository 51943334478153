.caption {
  position: relative;
  flex: 0 0 1.979%;
  max-width: 1.979%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.debug {
    flex: 0 0 1.6%;
    max-width: 1.6%;
  }
}

.genericCaptionText {
  font-size: 18px;
  color: #BCC3CA;
  opacity: 30%;
  white-space: nowrap;
  letter-spacing: 18px;
  rotate: -90deg;
}
