.group {
  display: flex;
  .group-header {
    display: flex;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    align-items: center;
    background: #000;
    justify-content: right;
    padding: 16px 8px;
    text-transform: uppercase;
    font-size: 14px;
  }
  .rows {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    border-top: 2px solid #657c93;
  }
}
