.hubsReport {
  display: flex;
  width: 100%;
  .table {
    display: flex;
    flex-direction: column;
    width: 100%;
    .content {
      display: flex;
      padding: 0;
      margin: 0;
      flex-direction: column;
      background: #1f2b37;
    }
  }
}
