.row {
  display: flex;
  flex-grow: 1;
  &.even {
    background: #0e151b;
    .today {
      background: #141f29;
    }
  }
  &.odd {
    background: #141f29;
    .today {
      background: #1f2b37;
    }
  }
  .hub-cell {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: 180px;
    min-width: 180px;
    padding: 4px 8px;
    gap: 4px;
    justify-content: center;
    .city {
      font-size: 18px;
      color: #b6b6b6;
    }
    .abbreviation {
      font-size: 12px;
      color: #87888b;
    }
  }
  .day-groups {
    display: flex;
    flex-grow: 1;
    .day-group {
      display: flex;
      width: calc(100% / 6);
      &.today {
        border-left: 2px solid black;
      }
      .cell {
        display: flex;
        flex-direction: column;
        width: calc(100% / 3);
        text-align: right;
        padding: 4px 16px;
        gap: 4px;
        justify-content: space-between;
        cursor: pointer;
        .amount {
          font-size: 24px;
          color: #b6b6b6;
          text-align: center;
          &.unavailable {
            color: #ff4848;
          }
        }
        .time {
          font-size: 12px;
          color: #87888b;
          text-align: center;
        }
      }
    }
  }
}
