.legend {
  position: absolute;
  bottom: 40px;
  left: 206px;
  display: flex;
  gap: 50px;

  .legendItem {
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
    color: #FFF;
    text-align: center;

    .shapeContainer {
      display: inline-block;

      .shape {
        margin: 3px;
        height: 6px;
        width: 6px;

        &.circle {
          border-radius: 50%;
        }
        &.rhombus {
          rotate: 45deg;
        }
      }
    }

    .caption {
      display: inline-block;
      margin: 0 14px;
      opacity: 30%;
    }
  }
}
