.infoTile {
  display: inline-flex;
  border: 1px solid #7F8083;
  border-radius: 2px;
  width: 47%;
  height: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;
  vertical-align: top;

  .icon, .captions {
    display: inline-block;
    margin: 0 10px;
  }

  .captions {
    color: #7F8083;
    font-weight: 600;
    text-align: left;

    .largeCaption {
      font-size: 36px;
    }

    .smallCaption {
      font-size: 24px;
    }
  }

  &.error {
    border-color: #FF3844;
    .captions {
      color: #FF3844;
    }
  }
}
