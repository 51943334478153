.circleIcon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  & .circle {
    border-radius: 50%;
  }

  & .rhombus {
    rotate: 45deg;

    & > svg {
      rotate: -45deg;
    }
  }

  & .shadow {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.15);
  }

  & .pin {
    position: absolute;
    border-style: solid;
  }
}


