.dataErrorsCountTile {
  display: inline-flex;
  border: 1px solid #7F8083;
  border-radius: 2px;
  width: 47%;
  height: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  font-weight: 600;
  color: #FF975D;

  .largeCaption {
    font-size: 68px;
    margin-right: 17px;
  }

  .smallCaption {
    font-size: 36px;
  }

  &.disabled {
    color: #7F8083
  }
}
